.body-stream {
  color: rgb(251 252 204 / 90%);
  position: absolute;
  height: auto;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Righteous', cursive;
}

@media screen and (max-width: 400px) {
  .body-stream {
    text-shadow: 2px 3px 2px #8fe6d56c;
    display: flex;
    justify-content: center;
    position: absolute;
    height: auto;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
