@media screen and (max-width: 1200px) {
  .layout {
    max-width: 80%;
  }



  .image-and-caption-container {
    width: max-content;
    /* max-width: 300px; */
  }

  .description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .description {
    position: unset;
    width: unset;
    align-self: center;
    text-align: center;
    margin-top: 1rem;
    max-width: 90%;
    overflow: hidden;
    padding: 2rem 0;
    font-size: 1rem;
}

  .description-right {
    position: unset;
    width: unset;
    align-self: center;
    text-align: center;
    max-width: 90%;
    overflow: hidden;
    padding: 1rem 0 2rem 0;
    font-size: 1rem;
}
}
