@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik+Burned&display=swap);
html {
  height: 100vh;
  background-color: black;
}

body {
  color: whitesmoke;
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 650px) {
  #body {
    padding-bottom: 6rem;
  }
}

a,h1,h2,h3,h4,h5,h6,li,ul {
  margin: 0;
  text-decoration: none;
}

li {
  list-style: none;
}

ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.header {
  position: relative;
  padding-top: 3rem;
  margin-bottom: 1rem;
}


.body-home {
  width: 80%;
  min-height: 500px;
  margin: auto;
  background-image: url(/static/media/IMG_7518.bc74f356.JPG);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10%;
}

@media screen and (min-width: 880px) {
    .body-home {
        width: 40%;
        height: 100vh;
        margin: auto;
        background-size: cover;
        
    }
}

/*-------Scroll Bar-----------------------------------------------------------*/
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    background: rgba(251, 252, 204, 0.9); 
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: black;
    /* background: grey; */
  }

.logo-image {
    position: fixed;
    bottom: 20px;
    border: .15rem outset rgba(5, 2, 2, 0.9);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background-position: center;
    background-image: url(/static/media/logo.d3d0fa01.png);
    margin: 1rem;
    opacity: 0.4;
  }
  
  .logo-image:hover {
    opacity: 1.0;
    box-shadow: 1px 1px 2px #8fe6d5, 0 0 0.7em rgb(255, 255, 255), 0 0 0.2em;
  }

  .home-page-button {
    font-family: 'Righteous', cursive;
    font-weight: bold;
    position: fixed;
    bottom: 54px;
    left: 18px;
    width: 80px;
    height: 60px;
    text-align: center;
    text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px rgb(0, 0, 0);
    z-index: 1;
    color: #8fe6d5;
    font-size: 1.5rem;
  }
.contact-now-button {
  font-family: 'Righteous', cursive;
  opacity: 0.8;
  background-color: #8fe6d5;
  padding: 0.5rem;
  border: 0.2rem dashed #8fe6d5;
  border-radius: 50%;
  font-size: 1.4rem;
  position: fixed;
  bottom: 33px;
  right: 20px;
  box-shadow: 1px 1px 2px black, 0 0 0.4em black, 0 0 0.2em;
}

.contact-now-button > a {
  color: rgb(245, 245, 223);
  text-transform: uppercase;
  /* color: rgb(5, 2, 2); */
  font-size: 1.2rem;
  font-weight: 600;
  /* text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.954); */
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px rgb(0, 0, 0);

}

.contact-now-button:hover {
  opacity: 0.8;
  background-color: rgb(0, 0, 0);
  border: 0.2rem solid rgba(173, 171, 171, 0.285);
  box-shadow: 1px -2px 10px rgb(245, 245, 223);;
}

.contact-now-button > a:hover {
  /* text-shadow: 4px 1px 10px rgb(0, 0, 0); */
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px rgb(0, 0, 0);
  color: rgb(245, 245, 223);
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

ul {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  align-self: auto;
  width: 60%;
}

.links > li > a {
  color: #8fe6d5;
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
}

li {
  font-family: 'Righteous', cursive;
  padding: 1rem 0.5rem 1rem 0rem;
  font-size: 2rem;
  text-align: center;
  flex-flow: wrap;
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
}

li:hover {
  font-family: "Rubik Burned", cursive;
  text-shadow: 1px 10px 4px rgb(251 252 204 / 90%), 0 0 1em rgb(4, 54, 148),
    0 0 0.2em black;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.links > li > a:hover {
  color: #fbf4e9;
}

@media screen and (max-width: 675px) {
  ul {
    width: 100%;
  }

  ul:hover {
    width: 90%;
  }

  ul.links {
    
  }

  ul.links > li {
    padding-top: 0rem;
    width: 100%;
  }

  li:hover {
    font-family: "Rubik Burned", cursive;
    /* background-color: rgba(58, 49, 49, 0.4); */
    font-size: 2rem;
    text-shadow: 1px 0x 2px white, 0 0 1em rgb(4, 54, 148), 0 0 0.2em black;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

.body-about {
  width: 80%;
  background-color: #8fe6d5;
  height: -webkit-max-content;
  height: max-content;
  min-height: -webkit-min-content;
  min-height: min-content;
  margin: auto;
  border-radius: 10%;
  padding-bottom: 6rem;
}

@media only screen and (max-width: 450px) {
  .about-container {
    margin-bottom: 3rem !important;
  }  
}


@media screen and (min-width: 880px) {
    .body-home {
        width: 40%;
        height: 100vh;
        margin: auto;
    }
}

.about-contact > a {
  font-size: 2rem;
  color:antiquewhite;
  text-shadow: 1px -1px 2px #030303;
}

.aboutMe {
  text-align: center;
  font-family: 'Righteous', cursive;
  max-width: -webkit-max-content;
  max-width: max-content;
  margin: auto;
 margin-bottom: 1rem;
}

.row-title-about {
  margin-top: 2rem;
  font-size: 2.5rem;
  color: rgb(251 252 204 / 90%);
  text-shadow: 1px -1px 2px #030303;
  z-index: -2;
}

.p-container {
  padding: 1%;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.about {
  /* color: rgb(251 252 204 / 100%); */
  color: rgb(0, 0, 0);
  overflow: hidden;
  width: 80%;
  max-width: 80%;
  text-indent: 1rem;
  font-size: 1.7rem;
  /* text-shadow: 1px -1px 2px #8fe6d5; */
  text-shadow: 1px -1px 2px #dcedea;
}


.booking-list-button a {
  color: whitesmoke;
  font-family: Righteous;
  font-size: 1.3rem;
  text-shadow: 1px 1px 2px black;
}

.booking-list-button {
  width: 100%;
  background-color: black;
  height: 2.5rem;
  border-radius: 0% 0% 60% 60%;
}

.booking-list-button a:hover {
  color: black;
  transition: color 2s;
}

.booking-list-button:hover {
  background-color: white;
  transition: background-color 2s;
}


.body-welcome {
    background: whitesmoke;
    box-shadow: 0px 10px 10px #8fe6d5;
    text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
    height: auto;
    padding-top: 1rem;
    overflow-y: hidden;
    font-family: 'Source Code Pro', monospace;
}

@media only screen and (min-width: 881px) and (max-width: 1250px){
    .body-welcome {
        right: 11%;
    }
  }

.booking-list-button a {
  color: whitesmoke;
  font-family: Righteous;
  font-size: 1.3rem;
  text-shadow: 1px 1px 2px black;
}

.booking-list-button {
  width: 100%;
  background-color: black;
  height: 2.5rem;
  border-radius: 0% 0% 60% 60%;
}

.booking-list-button a:hover {
  color: black;
  transition: color 2s;
}

.booking-list-button:hover {
  background-color: white;
  transition: background-color 2s;
}

.layout {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Righteous", cursive;
  margin: auto;
  color: #491B1D;
  text-indent: 0rem;
    /* color: rgb(251 252 204 / 100%); */
  /* text-shadow: 1px -1px 2px #8fe6d5; */
}

.row-title {
  color: rgb(251 252 204 / 90%);
  font-size: 4rem;
  text-shadow: 1px 2px 3px #491B1D, 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
}

.image-and-caption-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  border-top: 0.2rem outset rgb(251 252 204 / 100%);
  border-top-left-radius: 6%;
  border-top-right-radius: 6%;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 540px;
}

.image-and-caption-container > img {
  position: relative;
  border-radius: 2% 2% 0% 0%;
  max-width: inherit;
  max-height: inherit;
}

#performances-img {
  position: relative;
  z-index: 0;
  border-radius: 2% 2% 0% 0%;
  max-width: 440px;
  object-fit: cover;
  width: 100%;
  height: 540px;
  max-height: inherit;
  overflow: hidden;
}

.description-container {
  margin-bottom: 2rem;
}

.description {
  overflow: hidden;
  text-align: center;
  width: 30%;
  max-width: 80%;
  position: absolute;
  top: 12%;
  left: 1%;
  font-size: 1.5vw;
  margin-top: 2rem;
  box-shadow: 0px 10px 10px #8fe6d5;
}

.description-right {
  overflow-y: hidden;
  text-align: center;
  width: 30%;
  max-width: 80%;
  position: absolute;
  top: 12%;
  right: 1%;
  font-size: 1.5vw;
  margin-top: 2rem;
  box-shadow: 0px 10px 10px #8fe6d5;
}

@media only screen and (max-width: 540px) {
  #lessons-img {
    object-fit: cover;
    max-width: 300px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

@media only screen and (max-width: 290px) {
  #lessons-img {
    object-fit: cover;
    max-width: 200px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}
@media screen and (max-width: 1200px) {
  .layout {
    max-width: 80%;
  }



  .image-and-caption-container {
    width: -webkit-max-content;
    width: max-content;
    /* max-width: 300px; */
  }

  .description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .description {
    position: unset;
    width: unset;
    align-self: center;
    text-align: center;
    margin-top: 1rem;
    max-width: 90%;
    overflow: hidden;
    padding: 2rem 0;
    font-size: 1rem;
}

  .description-right {
    position: unset;
    width: unset;
    align-self: center;
    text-align: center;
    max-width: 90%;
    overflow: hidden;
    padding: 1rem 0 2rem 0;
    font-size: 1rem;
}
}

.body-stream {
  color: rgb(251 252 204 / 90%);
  position: absolute;
  height: auto;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Righteous', cursive;
}

@media screen and (max-width: 400px) {
  .body-stream {
    text-shadow: 2px 3px 2px #8fe6d56c;
    display: flex;
    justify-content: center;
    position: absolute;
    height: auto;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.grid-container {
    display: grid;
    grid-row-gap: 30px;
    grid-template-columns: auto auto;
    padding: 10px;
    max-width: 100%;
  }

  
  .grid-item {
    padding: 20px;
    font-size: 1.5rem;
    text-align: center;
  } 

  #am-descr {
    font-size: 1.9rem;
  }

  #apple-descr {
    font-size: 1.7rem;
  }
#scloud-descr{
  font-size: 1.7rem;
}

  @media screen and (max-width: 1035px) {
    .grid-container {
      display: flex;
      flex-flow: column wrap;
      padding: 10px;
    }
  }

  @media screen and (max-width: 668px) {
    .grid-container {
      display: flex;
      flex-flow: column wrap;
      
    }

    .grid-item {
      border: unset;
      padding: 0;
      font-size: 1.5rem;
      text-align: center;
    } 

    #am-descr {
      font-size: 1.8rem;
    }
  
    #apple-descr {
      font-size: 1rem;
    }
  #scloud-descr{
    font-size: 1.3rem;
  }
  }
.body-web {
  width: 80%;
  margin: auto;
  background-image: url(/static/media/IMG_7372.6ec5294d.JPG);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-width: 0.2rem;
  border-radius: 50%;
  border-style: solid;
  border-color: rgb(165, 240, 216);
  box-shadow: -5px -2px 25px 0px rgb(107, 103, 96);
}

@media only screen and (min-width: 880px) {
    .body-web {
        width: 70%;
        height: 80vh; 
    }
}

@media only screen and (max-width: 1200px) {
  .body-web {
    border-radius: 20%;
      width: 60%;
      height: 70vh; 
  }
}

@media only screen and (max-width: 500px) {
  .body-web {
    width: 80%;
  }
}


.caption-overlay {
    border-radius: inherit;
    background-color: rgba(28, 30, 29, 0.427);
    font-style: inherit;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
}

.about-web-p {
    text-align: center;
    font-size: 1.2rem;
    color: rgb(201, 230, 225);
    background-color: rgb(61, 59, 59, 30%);
}


.more-info:hover {
    background-color: black;
    color: rgb(201, 230, 225);
}

a {
    color: unset;
}

@media only screen and (max-width: 500px) {
    .caption-overlay {
        justify-content: center;
    }
}
