html {
  height: 100vh;
  background-color: black;
}

body {
  color: whitesmoke;
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 650px) {
  #body {
    padding-bottom: 6rem;
  }
}

a,h1,h2,h3,h4,h5,h6,li,ul {
  margin: 0;
  text-decoration: none;
}

li {
  list-style: none;
}

ul {
  padding-inline-start: 0;
}

.header {
  position: relative;
  padding-top: 3rem;
  margin-bottom: 1rem;
}
