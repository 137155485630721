.logo-image {
    position: fixed;
    bottom: 20px;
    border: .15rem outset rgba(5, 2, 2, 0.9);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    background-position: center;
    background-image: url(../../Images/logo.png);
    margin: 1rem;
    opacity: 0.4;
  }
  
  .logo-image:hover {
    opacity: 1.0;
    box-shadow: 1px 1px 2px #8fe6d5, 0 0 0.7em rgb(255, 255, 255), 0 0 0.2em;
  }

  .home-page-button {
    font-family: 'Righteous', cursive;
    font-weight: bold;
    position: fixed;
    bottom: 54px;
    left: 18px;
    width: 80px;
    height: 60px;
    text-align: center;
    text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px rgb(0, 0, 0);
    z-index: 1;
    color: #8fe6d5;
    font-size: 1.5rem;
  }