.grid-container {
    display: grid;
    grid-row-gap: 30px;
    grid-template-columns: auto auto;
    padding: 10px;
    max-width: 100%;
  }

  
  .grid-item {
    padding: 20px;
    font-size: 1.5rem;
    text-align: center;
  } 

  #am-descr {
    font-size: 1.9rem;
  }

  #apple-descr {
    font-size: 1.7rem;
  }
#scloud-descr{
  font-size: 1.7rem;
}

  @media screen and (max-width: 1035px) {
    .grid-container {
      display: flex;
      flex-flow: column wrap;
      padding: 10px;
    }
  }

  @media screen and (max-width: 668px) {
    .grid-container {
      display: flex;
      flex-flow: column wrap;
      
    }

    .grid-item {
      border: unset;
      padding: 0;
      font-size: 1.5rem;
      text-align: center;
    } 

    #am-descr {
      font-size: 1.8rem;
    }
  
    #apple-descr {
      font-size: 1rem;
    }
  #scloud-descr{
    font-size: 1.3rem;
  }
  }