.body-about {
  width: 80%;
  background-color: #8fe6d5;
  height: max-content;
  min-height: min-content;
  margin: auto;
  border-radius: 10%;
  padding-bottom: 6rem;
}

@media only screen and (max-width: 450px) {
  .about-container {
    margin-bottom: 3rem !important;
  }  
}


@media screen and (min-width: 880px) {
    .body-home {
        width: 40%;
        height: 100vh;
        margin: auto;
    }
}
