.contact-now-button {
  font-family: 'Righteous', cursive;
  opacity: 0.8;
  background-color: #8fe6d5;
  padding: 0.5rem;
  border: 0.2rem dashed #8fe6d5;
  border-radius: 50%;
  font-size: 1.4rem;
  position: fixed;
  bottom: 33px;
  right: 20px;
  box-shadow: 1px 1px 2px black, 0 0 0.4em black, 0 0 0.2em;
}

.contact-now-button > a {
  color: rgb(245, 245, 223);
  text-transform: uppercase;
  /* color: rgb(5, 2, 2); */
  font-size: 1.2rem;
  font-weight: 600;
  /* text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.954); */
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px rgb(0, 0, 0);

}

.contact-now-button:hover {
  opacity: 0.8;
  background-color: rgb(0, 0, 0);
  border: 0.2rem solid rgba(173, 171, 171, 0.285);
  box-shadow: 1px -2px 10px rgb(245, 245, 223);;
}

.contact-now-button > a:hover {
  /* text-shadow: 4px 1px 10px rgb(0, 0, 0); */
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px rgb(0, 0, 0);
  color: rgb(245, 245, 223);
}
