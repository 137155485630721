/*-------Scroll Bar-----------------------------------------------------------*/
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-track:hover {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    background: rgba(251, 252, 204, 0.9); 
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: black;
    /* background: grey; */
  }
