.about-contact > a {
  font-size: 2rem;
  color:antiquewhite;
  text-shadow: 1px -1px 2px #030303;
}

.aboutMe {
  text-align: center;
  font-family: 'Righteous', cursive;
  max-width: max-content;
  margin: auto;
 margin-bottom: 1rem;
}

.row-title-about {
  margin-top: 2rem;
  font-size: 2.5rem;
  color: rgb(251 252 204 / 90%);
  text-shadow: 1px -1px 2px #030303;
  z-index: -2;
}

.p-container {
  padding: 1%;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.about {
  /* color: rgb(251 252 204 / 100%); */
  color: rgb(0, 0, 0);
  overflow: hidden;
  width: 80%;
  max-width: 80%;
  text-indent: 1rem;
  font-size: 1.7rem;
  /* text-shadow: 1px -1px 2px #8fe6d5; */
  text-shadow: 1px -1px 2px #dcedea;
}


.booking-list-button a {
  color: whitesmoke;
  font-family: Righteous;
  font-size: 1.3rem;
  text-shadow: 1px 1px 2px black;
}

.booking-list-button {
  width: 100%;
  background-color: black;
  height: 2.5rem;
  border-radius: 0% 0% 60% 60%;
}

.booking-list-button a:hover {
  color: black;
  transition: color 2s;
}

.booking-list-button:hover {
  background-color: white;
  transition: background-color 2s;
}

