.layout {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Righteous", cursive;
  margin: auto;
  color: #491B1D;
  text-indent: 0rem;
    /* color: rgb(251 252 204 / 100%); */
  /* text-shadow: 1px -1px 2px #8fe6d5; */
}

.row-title {
  color: rgb(251 252 204 / 90%);
  font-size: 4rem;
  text-shadow: 1px 2px 3px #491B1D, 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
}

.image-and-caption-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  border-top: 0.2rem outset rgb(251 252 204 / 100%);
  border-top-left-radius: 6%;
  border-top-right-radius: 6%;
  max-width: fit-content;
  max-height: 540px;
}

.image-and-caption-container > img {
  position: relative;
  border-radius: 2% 2% 0% 0%;
  max-width: inherit;
  max-height: inherit;
}

#performances-img {
  position: relative;
  z-index: 0;
  border-radius: 2% 2% 0% 0%;
  max-width: 440px;
  object-fit: cover;
  width: 100%;
  height: 540px;
  max-height: inherit;
  overflow: hidden;
}

.description-container {
  margin-bottom: 2rem;
}

.description {
  overflow: hidden;
  text-align: center;
  width: 30%;
  max-width: 80%;
  position: absolute;
  top: 12%;
  left: 1%;
  font-size: 1.5vw;
  margin-top: 2rem;
  box-shadow: 0px 10px 10px #8fe6d5;
}

.description-right {
  overflow-y: hidden;
  text-align: center;
  width: 30%;
  max-width: 80%;
  position: absolute;
  top: 12%;
  right: 1%;
  font-size: 1.5vw;
  margin-top: 2rem;
  box-shadow: 0px 10px 10px #8fe6d5;
}

@media only screen and (max-width: 540px) {
  #lessons-img {
    object-fit: cover;
    max-width: 300px;
    min-width: fit-content;
  }
}

@media only screen and (max-width: 290px) {
  #lessons-img {
    object-fit: cover;
    max-width: 200px;
    min-width: fit-content;
  }
}