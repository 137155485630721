.body-home {
  width: 80%;
  min-height: 500px;
  margin: auto;
  background-image: url(../../Images/IMG_7518.JPG);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10%;
}

@media screen and (min-width: 880px) {
    .body-home {
        width: 40%;
        height: 100vh;
        margin: auto;
        background-size: cover;
        
    }
}
