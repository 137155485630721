@import url("https://fonts.googleapis.com/css2?family=Rubik+Burned&display=swap");

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

ul {
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  align-self: auto;
  width: 60%;
}

.links > li > a {
  color: #8fe6d5;
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
}

li {
  font-family: 'Righteous', cursive;
  padding: 1rem 0.5rem 1rem 0rem;
  font-size: 2rem;
  text-align: center;
  flex-flow: wrap;
  text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
}

li:hover {
  font-family: "Rubik Burned", cursive;
  text-shadow: 1px 10px 4px rgb(251 252 204 / 90%), 0 0 1em rgb(4, 54, 148),
    0 0 0.2em black;
  transform: scale(1.1);
}
.links > li > a:hover {
  color: #fbf4e9;
}

@media screen and (max-width: 675px) {
  ul {
    width: 100%;
  }

  ul:hover {
    width: 90%;
  }

  ul.links {
    
  }

  ul.links > li {
    padding-top: 0rem;
    width: 100%;
  }

  li:hover {
    font-family: "Rubik Burned", cursive;
    /* background-color: rgba(58, 49, 49, 0.4); */
    font-size: 2rem;
    text-shadow: 1px 0x 2px white, 0 0 1em rgb(4, 54, 148), 0 0 0.2em black;
    transform: scale(1.1);
  }
}
